<template>
  <div key="login"><!-- login window-->
    <div v-if="message !== ''"
         :class="`alert-${messageType}`"
         class="alert">{{ message }}
    </div>
    <div class="card border-primary shadow">
      <div class="card-header text-center">
        <h3 class="align-baseline">
          <img src="/img/logo.png" alt="logo" class="pr-2">
          Login
        </h3>
      </div>
      <div class="card-body">
        <form @submit="validateForm"
              method="post" accept-charset="utf-8"
              role="form"
              :action="action"
              class="form-signin">
          <div class="form-group row">
            <label for="username" class="col-md-4 col-form-label">Username</label>
            <div class="col-sm-8">
              <input id="username"
                     v-model="username"
                     name="username"
                     autocomplete="username"
                     tabindex="1"
                     placeholder="Username"
                     class="form-control"
                     :class="{
'is-invalid': attemptSubmit && username === ''
                   }">

              <div v-if="attemptSubmit && username === ''" class="invalid-feedback">You need to enter a
                <strong>username</strong></div>
            </div>
          </div>
          <div class="form-group row">
            <label for="password" class="col-md-4 col-form-label">Password</label>
            <div class="col-sm-8">
              <input id="password"
                     type="password"
                     name="password"
                     v-model="password"
                     autocomplete="current-password"
                     tabindex="2"
                     placeholder="Password"
                     class="form-control"
                     :class="{'is-invalid': attemptSubmit && password === ''}"
              >
              <div v-if="attemptSubmit && attemptSubmit && password === ''" class="invalid-feedback">You need to enter a
                <strong>password</strong></div>
            </div>
          </div>
          <div class="form-group row">
            <div v-if="showRememberMe" class="col col-sm-12 col-md-4">
              <div class="form-check">
                <input class="form-check-input" type="checkbox" name="remember_me" v-model="rememberMe"
                       id="remember-me-new" tabindex="3">
                <label class="form-check-label" for="remember-me-new">
                  Remember Me
                </label>
              </div>
            </div>
            <div class="col col-sm-12 col-md-8 d-flex justify-content-between">
              <button v-on:click.prevent="$emit('register', true)" type="button"
                      class="btn btn-outline-primary" tabindex="5">
                Become a member
              </button>
              <a href="/members/request-reset-password"
                 :class="['btn', 'btn-outline-primary', 'float-right','btn-light']"
                 tabindex="6">Request Password Reset</a>
            </div>
          </div>
          <cs-button type="primary"
                     class="btn-block"
                     buttonType="submit"
                     :disabled="!valid"
                     :loading="loading"
                     icon-left="login"
                     tabindex="4">Sign In
          </cs-button>
          <div style="display: none;">
            <input v-for="data in inputHiddenData"
                   :name="data.name"
                   :value="data.value"
                   :key="data.name" type="hidden">
          </div>
        </form>
      </div>
      <div class="card-footer">
        <p class="card-title mx-auto">Need assistance? Call Community Schools
          <a href="tel:(520)209-7551" class="btn btn-outline-info">
            <cs-icon icon="phone"/>
            (520)209-7551</a>

        </p>
      </div>
    </div>
  </div><!-- login window-->
</template>

<script>
export default {
  name: 'Login',
  props: {
    token: {
      type: [Boolean, String],
      required: true
    },
    initialUsername: {
      type: String,
      required: false,
      default: ''
    },
    initialPassword: {
      type: String,
      required: false,
      default: ''
    },
    inputHiddenData: {
      type: Array,
      required: true
    },
    action: {
      type: String,
      required: true
    },
    showRememberMe: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      username: this.initialUsername,
      password: this.initialPassword,
      confirm: '',
      attemptSubmit: false,
      loading: false,
      rememberMe: false,
      message: '',
      messageType: 'info'
    }
  },
  computed: {
    valid () {
      return this.username.trim() !== '' && this.password.trim() !== ''
    }
  },
  methods: {
    validateForm: function (event) {
      this.attemptSubmit = true
      if (!this.valid) {
        console.log('stop event')
        event.preventDefault()
      } else {
        console.log('continue event')
        return true
      }
    }
  }
}
</script>

<style scoped>

</style>
